'use client';

import useAuthStore from '@/stores/auth-store';
import { refreshToken } from './auth';

const sortURLSearchParams = (params: URLSearchParams) =>
  new URLSearchParams(
    Array.from(params.entries()).sort((a, b) => a[0].localeCompare(b[0])),
  );

function handleError(error: any, requestUrl: string): void {
  throw new Error(`API call error on ${requestUrl} | error: ${error}`);
}

async function handleResponse(
  response: Response,
  requestUrl: string,
): Promise<any> {
  if (!response.ok) {
    throw new Error(
      `Failed to load ${requestUrl} | status: ${response.status} ${response.statusText} ${response.body}`,
    );
  }
  if (response.status === 204) {
    return Promise.resolve(null);
  }
  return response.json();
}

export class ApiProxy {
  private apiUrl: URL;

  private service_account_key: string | undefined;

  private defaultParams: Record<string, string | string[]>;

  constructor(
    apiUrl: string,
    defaultParams: Record<string, string | string[]>,
    service_account_key?: string,
  ) {
    this.apiUrl = new URL(apiUrl);
    this.defaultParams = defaultParams;
    this.service_account_key = service_account_key;
  }

  GET_CACHE: Record<string, any> = {};

  generate_url(path: string, params?: URLSearchParams): URL {
    const requestPath = path.startsWith('/') ? path : `/${path}`;
    const requestParams = params || new URLSearchParams();

    Object.entries(this.defaultParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => requestParams.append(key, item));
      } else {
        requestParams.append(key, value);
      }
    });
    const requestUrl = new URL(requestPath, this.apiUrl.origin);
    requestUrl.search = sortURLSearchParams(requestParams).toString();
    return requestUrl;
  }

  async get(
    path: string,
    params?: URLSearchParams,
    useCache: boolean = true,
  ): Promise<any> {
    const requestUrl = this.generate_url(path, params).toString();
    if (useCache && requestUrl in this.GET_CACHE) {
      return this.GET_CACHE[requestUrl];
    }

    try {
      const fnLoad = async () =>
        fetch(requestUrl, {
          method: 'GET',
          credentials: 'include',
          headers: {
            ...(this.service_account_key
              ? { Authorization: `Bearer ${this.service_account_key}` }
              : {}),
          },
        });

      const response = await this.retryWithTokenRefresh(
        fnLoad,
        `GET on ${requestUrl}`,
      );

      const responseData = await handleResponse(response, requestUrl);
      if (useCache) {
        this.GET_CACHE[requestUrl] = responseData;
      }
      return responseData;
    } catch (error) {
      handleError(error, requestUrl);
      return null;
    }
  }

  async post(path: string, body: any, params?: URLSearchParams): Promise<any> {
    const requestUrl = this.generate_url(path, params).toString();
    try {
      const fnLoad = async () =>
        fetch(requestUrl, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            ...(this.service_account_key
              ? { Authorization: `Bearer ${this.service_account_key}` }
              : {}),
          },
          body: JSON.stringify(body),
        });

      const response = await this.retryWithTokenRefresh(
        fnLoad,
        `POST on ${requestUrl}`,
      );

      return await handleResponse(response, requestUrl);
    } catch (error) {
      handleError(error, requestUrl);
      return null;
    }
  }

  async put(path: string, body: any, params?: URLSearchParams): Promise<any> {
    const requestUrl = this.generate_url(path, params).toString();
    try {
      const fnLoad = async () =>
        fetch(requestUrl, {
          method: 'PUT',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            ...(this.service_account_key
              ? { Authorization: `Bearer ${this.service_account_key}` }
              : {}),
          },
          body: JSON.stringify(body),
        });

      const response = await this.retryWithTokenRefresh(
        fnLoad,
        `PUT on ${requestUrl}`,
      );

      return await handleResponse(response, requestUrl);
    } catch (error) {
      handleError(error, requestUrl);
      return null;
    }
  }

  async patch(path: string, body: any, params?: URLSearchParams): Promise<any> {
    const requestUrl = this.generate_url(path, params).toString();
    try {
      const fnLoad = async () =>
        fetch(requestUrl, {
          method: 'PATCH',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            ...(this.service_account_key
              ? { Authorization: `Bearer ${this.service_account_key}` }
              : {}),
          },
          body: JSON.stringify(body),
        });

      const response = await this.retryWithTokenRefresh(
        fnLoad,
        `PATCH on ${requestUrl}`,
      );

      return await handleResponse(response, requestUrl);
    } catch (error) {
      handleError(error, requestUrl);
      return null;
    }
  }

  async delete(path: string, params?: URLSearchParams): Promise<any> {
    const requestUrl = this.generate_url(path, params).toString();
    try {
      const fnLoad = async () =>
        fetch(requestUrl, {
          method: 'DELETE',
          credentials: 'include',
          headers: {
            ...(this.service_account_key
              ? { Authorization: `Bearer ${this.service_account_key}` }
              : {}),
          },
        });

      const response = await this.retryWithTokenRefresh(
        fnLoad,
        `DELETE: ${requestUrl}`,
      );

      return await handleResponse(response, requestUrl);
    } catch (error) {
      handleError(error, requestUrl);
      return null;
    }
  }

  async retryWithTokenRefresh(
    fnLoad: () => Promise<Response>,
    message?: string,
    numRetries = 3,
    msBetweenRetries = 10000,
  ): Promise<Response> {
    let response = await fnLoad();
    const authStore = useAuthStore.getState();

    for (let i = 0; i < numRetries - 1; i += 1) {
      if (response.ok) {
        return response;
      }
      if (response.status === 401) {
        refreshToken(
          this.apiUrl.toString(),
          () => {},
          () => {
            authStore.setLoggedOut();
            window.location.href = '/login';
          },
        );
      }

      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => {
        setTimeout(resolve, msBetweenRetries);
      });
      // eslint-disable-next-line no-console
      console.warn(`Retrying ${i + 1} of ${numRetries} for ${message}`);
      // eslint-disable-next-line no-await-in-loop
      response = await fnLoad();
    }

    return response;
  }
}
