import { ApiProxy } from '@/lib/api-proxy';
import { InstallationData } from '@/models/installation';
import { InstallationsData } from '@/models/installations';
import { SettingsData } from '@/models/settings';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface SessionState {
  settingsData: SettingsData | null;
  currentInstallation: InstallationData | null;
  installationsData: InstallationsData | null;
  apiProxy: ApiProxy | null;

  setSettingsData: (settingsData: SettingsData) => void;
  setCurrentInstallation: (
    currentInstallation: InstallationData | null,
  ) => void;
  setInstallationsData: (installationsData: InstallationsData | null) => void;
  clearSession: () => void;
}

const useSessionStore = create<SessionState>()(
  persist(
    (set) => ({
      currentInstallation: null,
      installationsData: null,
      settingsData: null,
      apiProxy: null,

      setCurrentInstallation: (installation) => {
        set((state) => {
          if (state.currentInstallation !== installation) {
            return {
              ...state,
              currentInstallation: installation,
              apiProxy:
                state.settingsData && installation
                  ? new ApiProxy(
                      state.settingsData?.apiUrl,
                      {
                        githubOwnerId: installation.account.id.toString(),
                      },
                      state.settingsData.__dev__?.service_account_key
                        ? state.settingsData.__dev__.service_account_key
                        : undefined,
                    )
                  : null,
            };
          }
          return state;
        });
      },
      setInstallationsData: (installations) => {
        set((state) => {
          if (state.installationsData !== installations) {
            return { ...state, installationsData: installations };
          }
          return state;
        });
      },
      setSettingsData: (settings) => {
        set((state) => {
          if (state.settingsData !== settings) {
            return { ...state, settingsData: settings };
          }
          return state;
        });
      },
      clearSession: () => {
        set((state) => {
          if (
            state.currentInstallation !== null ||
            state.installationsData !== null
          ) {
            return {
              ...state,
              currentInstallation: null,
              installationsData: null,
              apiProxy: null,
            };
          }
          return state;
        });
      },
    }),
    {
      name: 'nullify-dashboard-session',
      partialize: (state) => ({
        currentInstallation: state.currentInstallation,
        installationsData: state.installationsData,
        settingsData: state.settingsData,
      }),
    },
  ),
);

export default useSessionStore;
